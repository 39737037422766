.split-view {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 2rem;
}

.careers-img{
    height: auto;
    width: 100%;
    border-radius: 2rem;
}



