@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@200;400;600&family=Pinyon+Script&display=swap');

:root{
  font-size: 18px;
  width: 100vw;
  height: 100vh;
}

body {
    font-family: 'Montserrat', sans-serif;
}
h2 {
  font-size: 1.5rem;
  font-weight: bold;
}
.content {
  flex: 1;
  min-width: 100vw;
}

.content-item {
  padding: 0rem 4rem 0rem 4rem;
  height: 100%;
}

.appContainer {
  background: url(./imgs/bg.jpeg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  min-width: 100vw;
  min-height: 100vh;
  height: 100%;
  width: 100%;
  
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

}

.hidden {   
  display: none;
}

.bold{
  font-weight: 600;
}

.arsicault-title {
    font-family: 'Pinyon Script', cursive;
    display: inline-block;
}

@media only screen and (max-width: 991px) {
  :root{
    font-size: 14px;
  }
  .content-item{
    padding: .5rem .5rem 0rem .5rem;
  }
  .content {
    flex: 1;
    min-width: 100vw;
  }
}

@media only screen and (max-width: 991px) {
  :root{
    font-size: 14px;
  }
  .content-item{
    padding: .5rem .5rem 0rem .5rem;
  }
  .content {
    flex: 1;
    min-width: 100vw;
  }
}

@media only screen and (max-width: 1300px) {

  .content-item{
    padding: .5rem .5rem 0rem .5rem;
  }
  .content {
    flex: 1;
    min-width: 100vw;
  }
}
