.header-home {
  position: absolute;
  top: 0;
  left: 0;

  z-index: 10;
  font-size: 1rem;
  width: 100%;
  text-shadow: 1px 1px 0 #ffffff;
}

.header {
  position: inherit;
  display: flex;
  justify-content: flex-start;
  align-items: baseline;

  z-index: 10;
  font-size: 1rem;
  width: 100%;
  text-shadow: 1px 1px 0 #ffffff;
  margin-bottom: 1rem;
}

.header .logo-container {
  position: inheret;
  padding: 1rem;
  z-index: 111111;
}

.header-home .logo-container {
  position: fixed;
  padding: 1rem;
  z-index: 111111;
}

.logo {
  width: 9rem;
  height: 9rem;
  box-shadow: 2px 2px 1rem #d0d0d0;
  border-radius: 10rem;
}

.navbar {
  padding: 0rem;
  font-weight: bold;
}

.header-home .navbar {
  background-color: rgba(255, 255, 255, 0.5);
}

.header-home .navbar-nav {
  padding-top: 4.2rem;
  padding-left: 11rem;
}

.appContainer .navbar .navbar-nav a {
  color: rgba(0, 0, 0, 1);
  text-shadow: 1px 1px 0 #ffffff;
}

.appContainer .navbar .navbar-nav .nav-link.active {
  color: rgba(0, 0, 0, 1);
  font-weight: bolder;
}
.appContainer .navbar .navbar-nav a {
    text-decoration: none;
    background-image: linear-gradient(currentColor, currentColor);
    background-position: 0% 100%;
    background-repeat: no-repeat;
    background-size: 0% 2px;
    transition: background-size .3s;
}

.appContainer .navbar .navbar-nav a:hover{
    background-size: 100% 2px;
}

.instagram-icon {
  margin-left: .5rem;
  margin-right: .5rem;
  height: 1rem;
  width: auto;
}

.instagram-container {
  padding: 0 .5rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.fa-layers svg.instagram-pt1{
  margin-right: 5px; 
}
.fa-layers svg.instagram-pt2{
  color:white;
  margin-top: 1px;

}

.header-alert{
  position: fixed;
  top: 2rem;
  left: 11.5rem;
  color: red;
  // text-shadow: 1px 1px 0 #000;
}


@media only screen and (max-width: 991px) {
  
  .appContainer .header-home .navbar {
    padding: 0;
    padding-right: 1rem;
    display: flex;
    justify-content: flex-end;
    background-color: rgba(255, 255, 255, 0);
    width: auto;
    color: white;
    right: 0;
    left: auto;
  }

  .appContainer .header {
    padding: 0;
    padding-right: 1rem;
    position: inherit;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    z-index: 10;
    font-size: 1rem;
    width: 100%;
    text-shadow: 1px 1px 0 #ffffff;
    margin-bottom: 1rem;
  }

  .appContainer .header-home .navbar .navbar-nav {
    text-align: right;
    padding: 1rem;
    margin-top: 15px;
    background: rgba(255,255,255,.5);
    border-radius: 1rem;

  }

  .appContainer .header .navbar .navbar-nav {
    text-align: right;
    padding: 1rem;
    margin-top: 15px;
    background: rgba(255,255,255,.5);
    border-radius: 1rem;

  }

  // .navbar-nav{
  //   padding: 10px;
  //   margin-right: -1rem;
  //   background: rgba(255,255,255,.5);
  //   border-bottom-left-radius: 1rem;
  // }

  .nav-link {
    padding: 5px 0px;
  }

  .instagram-container {
    font-size: 1.8rem;
    margin-top: .5rem;
    padding-bottom: .5rem;
    padding-right: 0;
    margin-right: 0;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  .instagram-icon {
    margin: 7px 0;
    height: 2rem;
    width: auto;
  }
  .fa-layers svg.instagram-pt1{
    margin-right: 6px; 
  }
  .fa-layers svg.instagram-pt2{
    color:white;
  }

  .header-alert{
    position: fixed;
    top: 11rem;
    left: 1rem;
    color: red;
    text-shadow: 1px 1px 0 #000;
    width: 66vw;
  }

}
