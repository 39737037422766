.media-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(450px, 1fr));
    gap: 2rem;
    justify-items: center;
    padding: 0 4rem;

}

.media-card {
    width: 100%;
    max-width: 720px;
}

// .media-item {
//     width: 100%;
//     height: 56%;
// }


.media-caption {
    text-align: center;
}

.aspect-ratio {
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: 56.25%;
    /* The height of the item will now be 56.25% of the width. */
}

/* Adjust the iframe so it's rendered in the outer-width and outer-height of it's parent */
.aspect-ratio iframe {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
}