.split-view {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 2rem;
}

.gift-card{
    width: 100%;
    height: auto; 
    max-width: 500px;
}

.gc-card{
    align-items: center;
    justify-content: center;
    display: flex;
}

@media only screen and (max-width: 784px) {
    .gift-card{
        width: auto;
        height: 150px; 
    }
  }
  