.grid-container {
    display: grid;
}

.grid-container--fit {
    grid-template-columns: repeat(auto-fit, minmax(334px, 1fr));
}

.menu-container{

}

.no-margin{
    margin: 0;
}

.no-padding{
    padding:0;
}

.center-text{
    text-align: center;
}

.menu-container a{
    color: grey;
}

.menu-container a:active{
    color: black;
}

@media only screen and (max-width: 900px) {
    .center-text{

        text-align: left;
    }
  }
  