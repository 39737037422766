.footer {
    width: 100%;
    text-align: right;
    padding-right: 2rem;
    padding-bottom: 1rem;
    padding-top: 2rem;
    font-size: .50rem;
    z-index: 10;
}


.text-white {
    position: absolute;
    bottom: 0;
    right: 0;
    color: white;
}

.text-black {
    color: black;
}

.footer .arsicault-title {
    font-size: 1rem;
    padding-right: 1rem;
}

@media only screen and (max-width: 900px) {
    .footer {
        width: 100%;
        text-align: right;
        padding-right: 2rem;
        padding-bottom: 1rem;
        padding-top: 2rem;
        font-size: 1rem;
    }

    .footer .arsicault-title {
        font-size: 2rem;
        padding-right: 1rem;
    }

    .text-white {
        display: none;
        
    }
  }
  