.ABCoverImgContainer {
    background: url(./imgs/bg.jpeg);
    height: 100vh;
    width: 100vw;
    object-fit: cover;
    background-position: center;
    display: flex;
    align-items: center;
    justify-content: center;
    background-image: radial-gradient(circle, #b38a4c, #12532b, black);
    background-color: #74281a;
}


.ABCoverImg{
    // background: url(./imgs/pride_logo.jpeg);
    background: url(./imgs/logo.png);
    display: inline-block;
    height: 40vmin;
    width: 40vmin;
    background-position: center center;
    background-size: cover;
    border-radius: 20rem;
    box-shadow: 2px 2px 62px #ffd533;
}