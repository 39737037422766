.locations-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
    gap: 3rem;
}

.store-card {
}

.store-details {
    min-height: 10rem;
}

.note {
    font-size: .8rem;
    font-style: italic;
}