.home-container {
    height:100vh;
    width: 100vw;
}

.background-image-fullscreen{
    background-size: cover;
    background-position: center center;
    color: transparent;
    height: 100%;
    left: 0px;
    opacity: 0;
    position: absolute;
    width: 100%;
    z-index: 0;
}

.animate {
    animation: imageAnimation 21s linear infinite 0s;
}


// @keyframes imageAnimation { 
//     0% { opacity: 0; animation-timing-function: ease-in; }
//     3% { opacity: 1; animation-timing-function: ease-out; }
//     22% { opacity: 1 }
//     25% { opacity: 0 }
//     100% { opacity: 0 }
// }


@keyframes imageAnimation { 
    from { opacity: 0; animation-timing-function: ease-in; }
    3% { opacity: 1;   z-index: 1; }
    22% { opacity: 1; animation-timing-function: ease-out; }
    25% { opacity: 0; z-index: 0; }
    to { opacity: 0 }
}